import axios from 'axios';
import { getStatusError } from '../utils/helpres';
import { host } from '../utils/constants';
import decode from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../components/ToastAction';
import React from 'react';
import moment from 'moment';
const ERROR_MAINTENANCE = 503;

const login = (valuse) => async (dispatch) => {
  dispatch({ type: 'LOGIN_REQUESTING' });
  try {
    const { email, password } = valuse;
    const { data } = await axios.post(`${host}/api/login`, { email, password });
    const response = data.data;
    if (response && response.type === 'error')
      toast(({ closeToast }) => <ToastAction type="error" message={response.message} />, {
        hideProgressBar: true,
      });
    else {
      if (response && response.lawyer) {
        document.cookie = response.token;
        window.localStorage.setItem('token', response.token);
        dispatch({ type: 'LOGIN_SUCCESS', data: response });
      } else {
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        dispatch({ type: 'LOGIN_FAILURE', data: {} });
      }
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={response && response.data && response.data.message}
        />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'LOGIN_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
      status: getStatusError(err),
    });
  }
};

const getAccountWithOutTokenById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_EXPERT_REQUESTING' });
  try {
    const { data } = await axios.get(`${host}/api/lawyer/${id}`);
    dispatch({ type: 'GET_EXPERT_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_EXPERT_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getImg = (photo) => async (dispatch) => {
  dispatch({ type: 'GET_IMAGE_REQUESTING' });
  try {
    const data = await axios(`${host}${photo}`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${window.localStorage.getItem('token')}`,
      },
      responseType: 'blob', //Force to receive data in a Blob Format
    })
      .then(async (response) => {
        return URL.createObjectURL(new Blob([response.data]));
      })
      .catch((error) => {
        return null;
      });
    console.log(data);
    dispatch({ type: 'GET_IMAGE_SUCCESS', data });
  } catch (err) {
    dispatch({
      type: 'GET_IMAGE_FAILURE',
    });
  }
};
const getMe = () => async (dispatch) => {
  dispatch({ type: 'GET_ME_REQUESTING' });
  try {
    const url = `/api/lawyer`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ME_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      localStorage.clear();
    }
    dispatch({
      type: 'GET_ME_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getCountNotificationSeen = () => async (dispatch) => {
  dispatch({ type: 'GET_COUNT_NOTIFICATION_REQUESTING' });
  try {
    const url = `/api/notification/seen`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_COUNT_NOTIFICATION_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_COUNT_NOTIFICATION_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const updateNotification = () => async (dispatch) => {
  dispatch({ type: 'UPDATE_NOTIFICATION_REQUESTING' });
  try {
    const url = `/api/notification/is_seen`;
    const { data } = await apiCall({
      method: 'patch',
      url,
    });
    dispatch({ type: 'GUPDATE_NOTIFICATION_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'UPDATE_NOTIFICATION_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};

const getUser = (id) => async (dispatch) => {
  dispatch({ type: 'GET_USER_REQUESTING' });
  try {
    const url = `/api/lawyer/get/one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_USER_SUCCESS', user: data.data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_USER_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const apiCall = async (params) => {
  const token = window.localStorage.getItem('token');
  // Clear Authentication cache for IE
  // </AxiosResponse>document.execCommand('ClearAuthenticationCache', false);
  if (!token || isTokenExpired(token)) {
    // try for a new token
    return axios
      .post(
        `${host}/api/refreshToken`,
        { token },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        },
      )
      .then(({ data }) => {
        const response = data.data;
        window.localStorage.setItem('token', response.token);
        const newExtendedParams = {
          ...params,
          url: `${host + params.url}`,
          headers: {
            ...params.headers,
            Authorization: `JWT ${response.token}`,
          },
        };
        return newExtendedParams;
      })
      .then((newExtendedParams) => axios(newExtendedParams))
      .catch((apiError) => {
        if (apiError.response && apiError.response.status === ERROR_MAINTENANCE) {
          document.location.reload();
        }
        return Promise.reject(apiError);
      });
  }
  const extendedParams = {
    ...params,
    url: `${host + params.url}`,
    headers: {
      ...params.headers,
      Authorization: `JWT ${token}`,
    },
  };
  return axios(extendedParams).catch((apiError) => {
    if (apiError.response && apiError.response.status === ERROR_MAINTENANCE) {
      document.location.reload();
    }
    return Promise.reject(apiError);
  });
};
const changeActiveItemSideBar = (data, nameOfpage) => ({
  type: 'UPDATE_ACTIVE_ITEM_SIDE_BAR',
  activeItemSideBar: data,
  namePage: nameOfpage,
});

const getListSessions = (paramsSessions, type) => async (dispatch) => {
  dispatch({
    type: 'GET_SESSIONS_REQUESTING',
    paramsSessions,
  });
  try {
    const { customer_id } = paramsSessions || {
      customer_id: '',
    };
    const { search } = paramsSessions || {
      search: '',
    };
    const { order } = paramsSessions || {
      order: '',
    };
    const { date } = paramsSessions || {
      date: '',
    };
    const { currentPage } = paramsSessions || {
      currentPage: 1,
    };
    const { limit } = paramsSessions || {
      limit: '',
    };

    let url = `/api/session?type=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }
    if (customer_id !== '' && customer_id !== undefined) {
      url += `&customer_id=${customer_id}`;
    }
    if (order !== '') {
      url += `&order=${order}`;
    }
    if (date !== '') {
      url += `&date=${moment(date).add(1, 'days')}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_SESSIONS_SUCCESS',
      paramsSessions,
      sessions: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_SESSIONS_FAILURE',
      paramsSessions,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getAllSessions = () => async (dispatch) => {
  dispatch({
    type: 'GET_ALL_SESSIONS_REQUESTING',
  });
  try {
    let url = `/api/session/all`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_ALL_SESSIONS_SUCCESS',
      allSessions: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_ALL_SESSIONS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getListLawsuits = (paramsLawsuits, type) => async (dispatch) => {
  dispatch({
    type: 'GET_LAWSUITS_REQUESTING',
    paramsLawsuits,
  });
  try {
    const { search } = paramsLawsuits || {
      search: '',
    };
    const { customer_id } = paramsLawsuits || {
      customer_id: '',
    };
    const { order } = paramsLawsuits || {
      order: '',
    };
    const { date } = paramsLawsuits || {
      date: '',
    };
    const { currentPage } = paramsLawsuits || {
      currentPage: 1,
    };
    const { limit } = paramsLawsuits || {
      limit: '',
    };

    let url = `/api/lawsuit?type=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }
    if (customer_id !== '' && customer_id !== null) {
      url += `&customer_id=${customer_id}`;
    }
    if (order !== '') {
      url += `&order=${order}`;
    }
    if (date !== '') {
      url += `&date=${moment(date).add(1, 'days')}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAWSUITS_SUCCESS',
      paramsLawsuits,
      lawsuits: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAWSUITS_FAILURE',
      paramsLawsuits,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getListLawsuitsByIdCustomer = (customer_id) => async (dispatch) => {
  dispatch({
    type: 'GET_LAWSUITS_BY_CUSTOMER_REQUESTING',
  });
  try {
    let url = `/api/lawsuit/${customer_id}?limit=${10}&current_page=${1}`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAWSUITS_BY_CUSTOMER_SUCCESS',
      lawsuitsCustomer: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAWSUITS_BY_CUSTOMER_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getListCustomer = (paramsCustomer, type) => async (dispatch) => {
  dispatch({
    type: 'GET_CUSTOMERS_REQUESTING',
    paramsCustomer,
  });
  try {
    const { search } = paramsCustomer || {
      search: '',
    };
    const { currentPage } = paramsCustomer || {
      currentPage: 1,
    };
    const { limit } = paramsCustomer || {
      limit: '',
    };

    let url = `/api/customer?type=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CUSTOMERS_SUCCESS',
      paramsCustomer,
      customers: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CUSTOMERS_FAILURE',
      paramsCustomer,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getListCustomerByLawyer = () => async (dispatch) => {
  dispatch({
    type: 'GET_CUSTOMER_BY_LAWYER_REQUESTING',
  });
  try {
    let url = `/api/customer/list`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CUSTOMER_BY_LAWYER_SUCCESS',
      customers: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CUSTOMER_BY_LAWYER_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getListLawyers = () => async (dispatch) => {
  dispatch({
    type: 'GET_LIST_LAWYER_REQUESTING',
  });
  try {
    let url = `/api/lawyer/list`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LIST_LAWYER_SUCCESS',
      lawyers: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LIST_LAWYER_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringSession = (paramsSessions) => ({
  type: 'UPDATE_SEARCH_SESSIONS_PARAMS',
  paramsSessions,
});
const initialState = () => ({
  type: 'INITIAL_STATE_SESSION',
});
const initialStateLawsuits = () => ({
  type: 'INITIAL_STATE_LAWSUITS',
});
const getListLawsuitsWithOutSession = () => async (dispatch) => {
  dispatch({
    type: 'GET_LAWSUITS_SESSION_REQUESTING',
  });
  try {
    let url = `/api/lawsuit/lawyer/with-out/session`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAWSUITS_SESSION_SUCCESS',
      lawsuitsSessions: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAWSUITS_SESSION_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const changeSearchedStringLawsuits = (paramsLawsuits) => ({
  type: 'UPDATE_SEARCH_LAWSUITS_PARAMS',
  paramsLawsuits,
});
const changeSearchedStringCustomers = (paramsCustomer) => ({
  type: 'UPDATE_SEARCH_CUSTOMERS_PARAMS',
  paramsCustomer,
});
const getListSuitType = () => async (dispatch) => {
  dispatch({
    type: 'GET_SUIT_TYPE_REQUESTING',
  });
  try {
    let url = `/api/suit-type`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_SUIT_TYPE_SUCCESS',
      suitTypes: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_SUIT_TYPE_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getListJudgment = () => async (dispatch) => {
  dispatch({
    type: 'GET_JUDGMENT_REQUESTING',
  });
  try {
    let url = `/api/judgment`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_JUDGMENT_SUCCESS',
      judgments: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_JUDGMENT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getListTypeContract = () => async (dispatch) => {
  dispatch({
    type: 'GET_TYPE_CONTRACT_REQUESTING',
  });
  try {
    let url = `/api/type-contract`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_TYPE_CONTRACT_SUCCESS',
      typeContracts: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_TYPE_CONTRACT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const submitRegisterForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_REGISTER_REQUESTING' });
  const { last_name, first_name, phone, email, message } = values;
  let request = {
    first_name,
    last_name,
    phone,
    email,
    message,
  };
  try {
    const { data } = await axios.post(`${host}/api/register/create`, request);

    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_REGISTER_SUCCESS',
        register: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_REGISTER_FAILURE',
        ErrorMessage: data.message,
        register: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء الجلسة ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_REGISTER_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        register: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء الجلسة ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CUSTOMER_FAILURE',
        msg: 'error server',
        //err: data.errors || data.message,
        addCustomer: values,
        status: getStatusError(e),
      });
    }
  }
};

const getListReports = (paramsReport) => async (dispatch) => {
  dispatch({
    type: 'GET_REPORTS_REQUESTING',
    paramsReport,
  });
  try {
    const { search } = paramsReport || {
      search: '',
    };
    const { order } = paramsReport || {
      order: '',
    };
    const { date } = paramsReport || {
      date: '',
    };
    const { currentPage } = paramsReport || {
      currentPage: 1,
    };
    const { limit } = paramsReport || {
      limit: '',
    };

    let url = `/api/report?limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }
    if (order !== '') {
      url += `&order=${order}`;
    }
    if (date !== '') {
      url += `&date=${date}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_REPORTS_SUCCESS',
      paramsReport,
      reports: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_REPORTS_FAILURE',
      paramsReport,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringReports = (paramsReport) => ({
  type: 'UPDATE_SEARCH_REPORTS_PARAMS',
  paramsReport,
});
const submitUserForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_USER_REQUESTING' });
  const {
    first_name,
    zip_code,
    last_name,
    fix_number,
    office_code,
    address_city,
    address_street,
    tva,
    email,
    phone,
    adjective,
    signature,
  } = values;
  let request = {
    first_name,
    zip_code,
    last_name,
    fix_number,
    address_city,
    address_street,
    email,
    phone,
    adjective,
    tva,
    signature,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/lawyer/${values.id ? 'update' : 'create'}/${values.id || ''}`,
      data: request,
    });

    const response = data.data;
    if (!data.error) {
      if (values.photo && values.photo.length > 0 && values.photo[0].name) {
        const formData = new FormData();
        formData.append('file', values.photo[0]);
        formData.append('type_file', 'photo');
        formData.append('type', 'lawyer');
        formData.append('id', values.id);
        formData.append('type_doc', 'images');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      /* if (values.seal && values.seal.length > 0 && values.seal[0].name) {
        const formData = new FormData();
        formData.append('file', values.seal[0]);
        formData.append('type_file', 'seal');
        formData.append('type', 'lawyer');
        formData.append('id', values.id);
        formData.append('type_doc', 'images');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }*/
      if (values.seal && values.seal.length > 0 && values.seal[0].name) {
        const formData = new FormData();
        formData.append('file', values.seal[0]);
        formData.append('type_file', 'seal');
        formData.append('type', 'lawyer');
        formData.append('id', values.id);
        formData.append('type_doc', 'images');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم تغيير الملف الشخصي بنجاح" />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_USER_SUCCESS',
        updateAccount: response,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_USER_FAILURE',
        ErrorMessage: data.message,
        updateAccount: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({
      type: 'UPDATE_USER_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      updateAccount: values,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringAppeals = (paramsAppeals) => ({
  type: 'UPDATE_SEARCH_APPEALS_PARAMS',
  paramsAppeals,
});
const getListAppeals = (paramsAppeals) => async (dispatch) => {
  dispatch({
    type: 'GET_APPEALS_REQUESTING',
    paramsAppeals,
  });
  try {
    const { search } = paramsAppeals || {
      search: '',
    };
    const { order } = paramsAppeals || {
      order: '',
    };
    const { date } = paramsAppeals || {
      date: '',
    };
    const { currentPage } = paramsAppeals || {
      currentPage: 1,
    };
    const { limit } = paramsAppeals || {
      limit: '',
    };

    let url = `/api/appeal?limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }
    if (order !== '') {
      url += `&order=${order}`;
    }
    if (date !== '') {
      url += `&date=${date}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_APPEALS_SUCCESS',
      paramsAppeals,
      appeals: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_APPEALS_FAILURE',
      paramsAppeals,
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getAllRdvActif = () => async (dispatch) => {
  dispatch({ type: 'GET_RDV_ALL_ACTIF_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/appointment/actif`,
    });
    dispatch({
      type: 'GET_RDV_ALL_ACTIF_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_RDV_ALL_ACTIF_FAILURE',
      msg: 'error server',
      err: response.type || response.message,
    });
  }
};
const getCalender = (paramsCalender) => async (dispatch) => {
  dispatch({ type: 'GET_CALENDER_REQUESTING', paramsCalender });
  try {
    const { number_lawsuit } = paramsCalender || {
      number_lawsuit: '',
    };
    const { type } = paramsCalender || {
      type: '',
    };
    const { date } = paramsCalender || {
      date: '',
    };
    const { currentPage } = paramsCalender || {
      currentPage: 1,
    };
    const { limit } = paramsCalender || {
      limit: '',
    };
    let url = `/api/dashboard/get-calender?limit=${limit}&current_page=${currentPage}`;
    if (date !== '') {
      url += `&date=${date}`;
    }
    if (number_lawsuit !== '') {
      url += `&number_lawsuit=${number_lawsuit}`;
    }
    if (type !== '') {
      url += `&type=${type}`;
    }
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CALENDER_SUCCESS',
      data: data,
      paramsCalender,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_CALENDER_FAILURE',
      msg: 'error server',
      err: (response && response.type) || (response && response.message),
      paramsCalender,
    });
  }
};
const changeSearchedStringCalender = (paramsCalender) => ({
  type: 'UPDATE_SEARCH_CALENDER_PARAMS',
  paramsCalender,
});
const getEmail = (email) => async (dispatch) => {
  dispatch({ type: 'GET_EMAIL_USER_REQUESTING' });
  try {
    const url = `${host}/api/lawyer/find/email`;
    const { data } = await axios.post(url, { email });
    dispatch({ type: 'GET_EMAIL_USER_SUCCESS', data: data });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="تم ارسال رمز التحقق عبر البريد الالكتروني" />
      ),
      { hideProgressBar: true },
    );
  } catch (err) {
    const { response } = err;
    if (response) {
      const { data } = response;
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    }
    dispatch({
      type: 'GET_EMAIL_USER_FAILURE',
    });
  }
};
const submitResetPasswordForm = (values) => async (dispatch) => {
  dispatch({
    type: 'RESET_PASSWORD_REQUESTING',
  });
  const { email, password } = values;
  let request = {
    email,
    password,
  };
  try {
    const url = `${host}/api/lawyer/reset/password`;
    const { data } = await axios.post(url, request);
    //const response = data.data;
    if (!data.error) {
      dispatch({
        type: 'RESET_PASSWORD_SUCCESS',
        data: data,
      });
    } else {
      dispatch({
        type: 'RESET_PASSWORD_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    dispatch({
      type: 'RESET_PASSWORD_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const getListNotifications = (paramsNotification) => async (dispatch) => {
  dispatch({
    type: 'GET_NOTIFICATION_REQUESTING',
    paramsNotification,
  });
  try {
    const { date } = paramsNotification || {
      date: '',
    };
    const { currentPage } = paramsNotification || {
      currentPage: 1,
    };
    const { limit } = paramsNotification || {
      limit: '',
    };

    let url = `/api/notification?limit=${limit}&current_page=${currentPage}`;
    if (date !== '') {
      url += `&date=${date}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_NOTIFICATION_SUCCESS',
      paramsNotification,
      notifications: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_NOTIFICATION_FAILURE',
      paramsNotification,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringNotification = (paramsNotification) => ({
  type: 'UPDATE_SEARCH_NOTIFICATION_PARAMS',
  paramsNotification,
});
const getNotificationIsSeen = () => async (dispatch) => {
  dispatch({
    type: 'GET_NOTIFICATION_IS_SEEN_REQUESTING',
  });
  try {
    let url = `/api/notification/is_seen`;
    const { data } = await apiCall({
      method: 'patch',
      url,
    });
    dispatch({
      type: 'GET_NOTIFICATION_IS_SEEN_SUCCESS',
      notificationIsSeen: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_NOTIFICATION_IS_SEEN_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getListContract = (paramsContract, type) => async (dispatch) => {
  dispatch({
    type: 'GET_CONTRACTS_REQUESTING',
    paramsContract,
  });
  try {
    const { search } = paramsContract || {
      search: '',
    };
    const { order } = paramsContract || {
      order: '',
    };
    const { date } = paramsContract || {
      date: '',
    };
    const { currentPage } = paramsContract || {
      currentPage: 1,
    };
    const { limit } = paramsContract || {
      limit: '',
    };

    let url = `/api/contract?type=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }
    if (order !== '') {
      url += `&order=${order}`;
    }
    if (date !== '') {
      url += `&date=${date}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CONTRACTS_SUCCESS',
      paramsContract,
      contracts: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CONTRACTS_FAILURE',
      paramsContract,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringContract = (paramsContract) => ({
  type: 'UPDATE_SEARCH_CONTRACTS_PARAMS',
  paramsContract,
});
const changeSearchedStringBill = (paramsBill) => ({
  type: 'UPDATE_SEARCH_BILL_PARAMS',
  paramsBill,
});

const getListBills = (paramsBill, type) => async (dispatch) => {
  dispatch({
    type: 'GET_BILLS_REQUESTING',
    paramsBill,
  });
  try {
    const { search } = paramsBill || {
      search: '',
    };
    const { order } = paramsBill || {
      order: '',
    };
    const { date } = paramsBill || {
      date: '',
    };
    const { currentPage } = paramsBill || {
      currentPage: 1,
    };
    const { limit } = paramsBill || {
      limit: '',
    };

    let url = `/api/bill?type=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search !== '') {
      url += `&search=${search}`;
    }
    /*if (order !== '') {
      url += `&order=${order}`;
    }*/
    if (date !== '') {
      url += `&date=${date}`;
    }

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILLS_SUCCESS',
      paramsBill,
      bills: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILLS_FAILURE',
      paramsBill,
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getLastBill = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_BILL_REQUESTING' });
  try {
    let url = `/api/bill/last`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAST_BILL_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAST_BILL_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeCountMessage = (data) => ({
  type: 'UPDATE_COUNT_MESSAGE_SEEN',
  countMessageIsSeen: data,
});

const logout = (id) => async (dispatch) => {
  dispatch({ type: 'ADD_LOGOUT_REQUESTING' });
  try {
    const url = `/api/logout/${id}`;
    const { data } = await apiCall({
      method: 'post',
      url,
    });
    dispatch({ type: 'ADD_LOGOUT_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_LOGOUT_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getStatistic = () => async (dispatch) => {
  dispatch({ type: 'GET_STATISTIC_REQUESTING' });
  try {
    const url = `/api/dashboard/statistical`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_STATISTIC_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_STATISTIC_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitSecretaryForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SECRETARY_REQUESTING' });
  const {
    permissions,
    password,
    first_name,
    last_name,
    fix_number,
    type,
    city,
    street,
    photo,
    email,
    phone,
  } = values;
  let request = {
    permissions,
    first_name,
    type,
    last_name,
    fix_number,
    city,
    street,
    email,
    phone,
    password,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/secretary/${values.id ? 'update' : 'create'}/${values.id || ''}`,
      data: request,
    });
    const response = data.data;
    if (!data.error) {
      if (values.photo && values.photo.length > 0 && values.photo[0].name) {
        const formData = new FormData();
        formData.append('file', values.photo[0]);
        formData.append('type', 'secretary');
        formData.append('id', response.id);
        formData.append('type_doc', 'images');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="تم تغيير المستخدم بنجاح" />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_SECRETARY_SUCCESS',
        data: response,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SECRETARY_FAILURE',
        ErrorMessage: data.message,
        updateAccount: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
          hideProgressBar: true,
        });
      dispatch({
        type: 'UPDATE_SECRETARY_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        updateAccount: values,
        status: getStatusError(e),
      });
    }
  }
};
const submitLawyerTraineeShipForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_LAWYER_TRAINEE_SHIP_REQUESTING' });
  const {
    permissions,
    first_name,
    pack,
    zip_code,
    last_name,
    fix_number,
    city,
    street,
    password,
    email,
    phone,
  } = values;
  let request = {
    permissions,
    password,
    pack,
    first_name,
    zip_code,
    last_name,
    fix_number,
    address_city: city,
    address_street: street,
    email,
    phone,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/lawyer-traineeship/${values.id ? 'update' : 'create'}/${
        values.id || ''
      }`,
      data: request,
    });
    const response = data.data;
    if (!data.error) {
      if (values.photo && values.photo.length > 0 && values.photo[0].name) {
        const formData = new FormData();
        formData.append('file', values.photo[0]);
        formData.append('type_file', 'photo');
        formData.append('type', 'lawyer');
        formData.append('id', data.data.id);
        formData.append('type_doc', 'images');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => <ToastAction type="success" message="تم تغيير الملف بنجاح" />,
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_LAWYER_TRAINEE_SHIP_SUCCESS',
        updateAccount: response,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_LAWYER_TRAINEE_SHIP_FAILURE',
        ErrorMessage: data.message,
        updateAccount: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({
      type: 'UPDATE_LAWYER_TRAINEE_SHIP_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      updateAccount: values,
      status: getStatusError(e),
    });
  }
};
const changeSandwichMenuBtn = () => ({
  type: 'UPDATE_SANDWICH_MENU_BTN',
});
export default {
  submitLawyerTraineeShipForm,
  changeSandwichMenuBtn,
  getStatistic,
  submitSecretaryForm,
  changeCountMessage,
  getLastBill,
  changeSearchedStringBill,
  getListBills,
  logout,
  getListContract,
  changeSearchedStringContract,
  changeSearchedStringNotification,
  submitResetPasswordForm,
  getListAppeals,
  getAllRdvActif,
  changeSearchedStringAppeals,
  getListSuitType,
  submitUserForm,
  login,
  initialState,
  getAccountWithOutTokenById,
  getMe,
  changeActiveItemSideBar,
  getListSessions,
  changeSearchedStringSession,
  getListLawsuitsWithOutSession,
  changeSearchedStringLawsuits,
  getListLawsuits,
  initialStateLawsuits,
  getListCustomerByLawyer,
  getListCustomer,
  changeSearchedStringCustomers,
  getListLawsuitsByIdCustomer,
  submitRegisterForm,
  getListLawyers,
  getListReports,
  changeSearchedStringReports,
  getUser,
  getListTypeContract,
  getListJudgment,
  getAllSessions,
  updateNotification,
  getEmail,
  getListNotifications,
  getNotificationIsSeen,
  getCountNotificationSeen,
  getCalender,
  changeSearchedStringCalender,
  getImg,
};
