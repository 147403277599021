import React, { Component } from 'react';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import actionsSession from '../../session/store/actions';
import { isHasPermission } from '../../../utils/helpres';

const mapStateToProps = (state) => ({
  sessions: state.AppReducer.sessions,
  isLoadingSessions: state.AppReducer.isLoadingSessions,
  paramsSessions: state.AppReducer.paramsSessions,
  destroySession: state.SessionReducer.destroySession,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getListSessions: (paramsSessions) =>
    dispatch(actions.getListSessions(paramsSessions, 1)),
  deleteSession: async (paramsSessions, id) => {
    await dispatch(actionsSession.deleteSession(id, 'delete'));
    dispatch(actions.getListSessions(paramsSessions, 1));
  },
  changeSearchedStringSession: async (paramsSessions) => {
    await dispatch(actions.changeSearchedStringSession(paramsSessions));
    dispatch(actions.getListSessions(paramsSessions, 1));
  },
});

class SessionArchive extends Component {
  constructor(props) {
    super(props);
    props.getListSessions(props.paramsSessions, 1);
    props.changeActiveItemSideBar(6, 'أرشيف الجلسات');
    document.title = 'أرشيف الجلسات';
  }
  render() {
    const { account, sessions, destroySession, isLoadingSessions, paramsSessions } =
      this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-الجلسات',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-الجلسات',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-الجلسات',
    );
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <Table
            class1="jalaset__judgements_table"
            class2="jalaset_table"
            colSpan={5}
            dataTable="session"
            messageDelete="أنت بصدد حذف الجلسة"
            withTitle={false}
            action={true}
            isLoading={isLoadingSessions}
            params={paramsSessions}
            changeSearchedString={this.props.changeSearchedStringSession}
            data={sessions}
            //sessions={sessions}
            title="الجلسات"
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            deletePath={this.props.deleteSession}
            destroy={destroySession}
            updateSession={false}
            //addSession={addSession}
            updateSessionFunction={this.props.updateSession}
            destroyYes={true}
            headerItems={[
              'الحريف',
              'الدعوى',
              'تاريخ الجلسة',
              'المحكمة',
              'نوع الجلسة',
              'الإعدادت',
            ]}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionArchive);
