import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsCustomer from './store/actions';
import { connect } from 'react-redux';
import SearchIcon from '../../assets/img/search-interface-symbol_sm.svg';
import { Link } from 'react-router-dom';
import Item from './components/item';
import { Skeleton } from '@material-ui/lab';
import EmptyData from '../../assets/img/undraw_design_team_af2y.svg';
import moment from 'moment';
import { isHasPermission } from '../../utils/helpres';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state) => ({
  customerSelected: state.AppReducer.customerSelected,
  customers: state.AppReducer.customers,
  isLoadingCustomers: state.AppReducer.isLoadingCustomers,
  customer: state.AppReducer.customer,
  isLoadingCustomer: state.AppReducer.isLoadingCustomer,
  paramsCustomer: state.AppReducer.paramsCustomer,
  isLoadingLawsuitsCustomers: state.AppReducer.isLoadingLawsuitsCustomers,
  lawsuitsCustomer: state.AppReducer.lawsuitsCustomer,
  account: state.AppReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  getListCustomer: (paramsCustomer) =>
    dispatch(actions.getListCustomer(paramsCustomer, 0)),
  getCustomerById: (id) => dispatch(actionsCustomer.getCustomerById(id)),
  getListLawsuitsByIdCustomer: (customer_id) =>
    dispatch(actions.getListLawsuitsByIdCustomer(customer_id)),
  changeSearchedStringCustomers: async (paramsCustomer) => {
    await dispatch(actions.changeSearchedStringCustomers(paramsCustomer));
    dispatch(actions.getListCustomer(paramsCustomer, 0));
  },
  deleteCustomer: async (paramsCustomer, id) => {
    await dispatch(actionsCustomer.deleteCustomer(id));
    dispatch(actions.getListCustomer(paramsCustomer, 0));
  },
});

class Session extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBar(8, 'ملفات الحرفاء');
    document.title = 'ملفات الحرفاء';
    this.state = {
      customer_selected: null,
      customer_selected_code: null,
      openDeletePopup: false,
    };
    this.handleChangedCustomerSelected = this.handleChangedCustomerSelected.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringCustomers({
      search: '',
      currentPage: 1,
      limit: 5,
    });
  }
  handleChangedCustomerSelected(id, code) {
    this.setState({
      customer_selected: id,
      customer_selected_code: code,
    });
    this.props.getCustomerById(id);
    this.props.getListLawsuitsByIdCustomer(code);
  }
  render() {
    const {
      customer,
      isLoadingCustomer,
      isLoadingLawsuitsCustomers,
      customers,
      account,
      paramsCustomer,
      lawsuitsCustomer,
    } = this.props;

    console.log(lawsuitsCustomer);
    const { customer_selected, customer_selected_code, openDeletePopup } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-ملفات-الحرفاء',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-ملفات-الحرفاء',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-ملفات-الحرفاء',
    );
    let isPermissionAddLawsuit = isHasPermission(
      account.login_access.permissions,
      'إضافة-الدعاوى',
    );
    return (
      <div className="clients_middle">
        <div>
          {isPermissionAdd ? (
            <Link
              to="/add-customer"
              className="clients_add_button clients_add_client_btn"
            >
              <svg
                height="10"
                viewBox="0 0 448 448"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
              </svg>
              اضافة حريف
            </Link>
          ) : (
            ''
          )}
        </div>

        <div className="clients_middle__wrapper">
          <>
            <div className="clients_col-right">
              <div className="clients_right_search">
                <img src={SearchIcon} alt="search icon" />
                <input
                  onChange={(e) =>
                    this.props.changeSearchedStringCustomers({
                      ...paramsCustomer,
                      search: e.target.value,
                    })
                  }
                  value={paramsCustomer && paramsCustomer.search}
                  type="text"
                />
              </div>
              {customers && customers.data && customers.data.length > 0
                ? customers.data.map((customer) => {
                    return (
                      <Item
                        handleChangedCustomerSelected={this.handleChangedCustomerSelected}
                        getCustomerById={this.props.getCustomerById}
                        getListLawsuitsByIdCustomer={
                          this.props.getListLawsuitsByIdCustomer
                        }
                        customer_selected={customer_selected || customers.data[0].id}
                        customer_selected_code={
                          customer_selected_code || customers.data[0].code
                        }
                        customer={customer}
                        key={customer.id}
                      />
                    );
                  })
                : ''}
            </div>
            {customers && customers.data && customers.data.length > 0 ? (
              <div className="clients_col-left">
                <div className="clients_details_section">
                  <div
                    style={{
                      height: '3rem',
                      fontWeight: 'bold',
                    }}
                    className="clients_details_section__head"
                  >
                    بيانات الحريف
                    <div style={{ display: 'flex' }}>
                      {customer && customer.id && isPermissionEdit ? (
                        <Tooltip title="تحديث">
                          <Link to={`/edit-customer/${customer.id}`}>
                            <svg
                              id="Group_23"
                              data-name="Group 23"
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.304"
                              height="24.288"
                              viewBox="0 0 23.304 24.288"
                              style={{ cursor: 'pointer' }}
                            >
                              <g id="Group_22" data-name="Group 22">
                                <path
                                  id="Path_86"
                                  data-name="Path 86"
                                  d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                  transform="translate(-24.83 0)"
                                  fill="#ca2946"
                                ></path>
                                <path
                                  id="Path_87"
                                  data-name="Path 87"
                                  d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                  transform="translate(-7.838 -342.742)"
                                  fill="#ca2946"
                                ></path>
                              </g>
                            </svg>
                          </Link>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                      {customer && customer.id && isPermissionDelete ? (
                        <Tooltip title="حذف">
                          <div className="jalaset_table__del">
                            <svg
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({
                                  customer_selected: customer.id,
                                  openDeletePopup: true,
                                });
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.906"
                              height="25.74"
                              viewBox="0 0 20.906 25.74"
                            >
                              <g id="delete" transform="translate(0 0)">
                                <path
                                  id="Path_82"
                                  data-name="Path 82"
                                  d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                                  transform="translate(-208.991 -145.377)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_83"
                                  data-name="Path 83"
                                  d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                                  transform="translate(-98.105 -145.377)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_84"
                                  data-name="Path 84"
                                  d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                                  transform="translate(0.003 0.002)"
                                  fill="#ca2946"
                                />
                                <path
                                  id="Path_85"
                                  data-name="Path 85"
                                  d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                                  transform="translate(-153.548 -145.377)"
                                  fill="#ca2946"
                                />
                              </g>
                            </svg>
                          </div>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {customer && customer.id ? (
                    isLoadingCustomer ? (
                      <div className="clients_details_section__body">
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={50}
                              height={20}
                            />
                          </div>
                          <div className="clients_data_name">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={150}
                              height={20}
                            />
                          </div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={50}
                              height={20}
                            />
                          </div>
                          <div className="clients_data_name">
                            <Skeleton
                              style={{
                                padding: '10px',
                              }}
                              variant="rect"
                              width={150}
                              height={20}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="clients_details_section__body">
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">عدد الملف</div>
                          <div className="clients_data_name">{customer.number_field}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">تاريخ</div>
                          <div className="clients_data_name">
                            {moment(customer.created_at).format('YYYY-MM-DD HH:mm:ss')}
                          </div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">كود</div>
                          <div className="clients_data_name">{customer.code}</div>
                        </div>
                        <div className="clients_details_section__field">
                          <div className="clients_field_title">الإسم</div>
                          <div className="clients_data_name">{customer.name}</div>
                        </div>
                        {customer.legal_agent && (
                          <div className="clients_details_section__field">
                            <div className="clients_field_title">الوكيل القانوني</div>
                            <div className="clients_data_name">
                              {customer.legal_agent}
                            </div>
                          </div>
                        )}

                        <div className="clients_details_section__field">
                          <div className="clients_field_title">ب ت</div>
                          <div className="clients_data_addr">{customer.cin}</div>
                        </div>

                        <div className="clients_details_section__field">
                          <div className="clients_field_title">الهاتف</div>
                          <div className="clients_data_phone">{customer.phone}</div>
                        </div>

                        {customer.post && (
                          <div className="clients_details_section__field">
                            <div className="clients_field_title">المهنة</div>
                            <div className="clients_data_addr">{customer.post}</div>
                          </div>
                        )}

                        {customer.nationality && (
                          <div className="clients_details_section__field">
                            <div className="clients_field_title">الجنسية</div>
                            <div className="clients_data_addr">
                              {customer.nationality}
                            </div>
                          </div>
                        )}
                        {customer.address_city && (
                          <div className="clients_details_section__field">
                            <div className="clients_field_title">المدينة</div>
                            <div className="clients_data_city">
                              {customer.address_city}
                            </div>
                          </div>
                        )}

                        <div className="clients_details_section__field">
                          <div className="clients_field_title">العنوان</div>
                          <div className="clients_data_addr">{` ${customer.address_street} - ${customer.address_city} `}</div>
                        </div>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>

                <div className="clients_table_section">
                  <div className="clients_table">
                    <table>
                      <thead>
                        <tr>
                          <th>الدعوى</th>
                          <th>التاريخ</th>
                          <th>المحكمة</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoadingLawsuitsCustomers &&
                        lawsuitsCustomer &&
                        lawsuitsCustomer.data ? (
                          lawsuitsCustomer.data.length > 0 &&
                          lawsuitsCustomer.data.map((item) => {
                            return (
                              <tr
                                onClick={() => {
                                  this.props.history.push(`/edit-lawsuit/${item.id}`);
                                }}
                                key={item.id}
                              >
                                <td>{item.number}</td>
                                <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                <td>{item.court}</td>
                                <td>
                                  <Tooltip title="تحديث">
                                    <Link to={`/edit-lawsuit/${item.id}`}>
                                      <div
                                        style={{
                                          padding: '4px',
                                        }}
                                      >
                                        <svg
                                          style={{ cursor: 'pointer' }}
                                          id="Group_23"
                                          data-name="Group 23"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23.304"
                                          height="24.288"
                                          viewBox="0 0 23.304 24.288"
                                        >
                                          <g id="Group_22" data-name="Group 22">
                                            <path
                                              id="Path_86"
                                              data-name="Path 86"
                                              d="M26.636,19.431h.131L33.1,18.053a.623.623,0,0,0,.328-.164L44.163,7.156A4.136,4.136,0,0,0,45.377,4.2a4.267,4.267,0,0,0-1.214-2.987A4.135,4.135,0,0,0,41.209,0a4.037,4.037,0,0,0-2.954,1.247l-10.7,10.733a.591.591,0,0,0-.2.3l-1.378,6.335a.722.722,0,0,0,.2.624A.656.656,0,0,0,26.636,19.431ZM41.209,1.314a2.855,2.855,0,0,1,2.856,2.855q0,.016,0,.033a2.757,2.757,0,0,1-.821,2l-4.037-4.07A2.823,2.823,0,0,1,41.209,1.314ZM38.287,3.086l4.037,4.037L32.97,16.444l-4.037-4ZM28.408,13.753,31.657,17l-4.168.919Z"
                                              transform="translate(-24.83 0)"
                                              fill="#ca2946"
                                            />
                                            <path
                                              id="Path_87"
                                              data-name="Path 87"
                                              d="M30.485,365.717H8.494a.656.656,0,0,0,0,1.313H30.485a.656.656,0,0,0,0-1.313Z"
                                              transform="translate(-7.838 -342.742)"
                                              fill="#ca2946"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </Link>
                                  </Tooltip>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            {' '}
                            <td colSpan={3}>لا يوجد سجل متاح</td>
                          </tr>
                        )}
                      </tbody>
                      {lawsuitsCustomer && lawsuitsCustomer.length > 0 ? (
                        <tfoot>
                          <tr>
                            <td colSpan="3">
                              <Link
                                to={{
                                  pathname: '/lawsuits',
                                  state: {
                                    customer_id: customer.id,
                                  },
                                }}
                              >
                                <div className="home_table__footer">
                                  المزيد
                                  <svg
                                    className=""
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.225"
                                    height="5.492"
                                    viewBox="0 0 15.225 5.492"
                                  >
                                    <path
                                      id="Path_53"
                                      data-name="Path 53"
                                      d="M3020.1,1976.5l7.3,4.478,7.4-4.478"
                                      transform="translate(-3019.841 -1976.072)"
                                      fill="none"
                                      stroke="var(--color-primary-red)"
                                      strokeWidth="1"
                                    />
                                  </svg>
                                </div>
                              </Link>
                            </td>
                          </tr>
                        </tfoot>
                      ) : (
                        ''
                      )}
                    </table>
                  </div>
                  {isPermissionAddLawsuit ? (
                    <div className="clients_table_section__btn_grp">
                      <Link to="/add-lawsuit" className="clients_add_button">
                        <svg
                          height="10"
                          viewBox="0 0 448 448"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                        </svg>
                        دعوى
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="clients_col-right"></div>
                <div
                  style={{ gap: '10%', marginTop: '8%', display: 'grid' }}
                  className="clients_col-left"
                >
                  <img style={{ textAlign: 'center' }} src={EmptyData} />
                  <span style={{ marginRight: '12%', fontSize: '15px' }}>
                    لا يوجد سجل متاح
                  </span>
                </div>
              </>
            )}
          </>
        </div>
        <div className={openDeletePopup ? 'popup active' : 'popup'}>
          <div className="popup__title">أنت بصدد ايداع هذا الحريف في الارشيف</div>
          <div className="popup__btn_grp">
            <button
              onClick={() =>
                this.setState({ customer_selected: null, openDeletePopup: false })
              }
              className="cancel_popup"
            >
              <span>الغاء</span>
              <span>&lt;</span>
            </button>
            <button
              onClick={async () => {
                await this.props.deleteCustomer(paramsCustomer, customer_selected);
                this.setState({ customer_selected: null, openDeletePopup: false });
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.906"
                  height="25.74"
                  viewBox="0 0 20.906 25.74"
                >
                  <g id="delete" transform="translate(0.003 0.002)">
                    <path
                      id="Path_82"
                      data-name="Path 82"
                      d="M223,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,223,154.7Zm0,0"
                      transform="translate(-208.995 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_83"
                      data-name="Path 83"
                      d="M105,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,105,154.7Zm0,0"
                      transform="translate(-98.108 -145.379)"
                      fill="#fff"
                    />
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M1.709,7.662V22.514a3.326,3.326,0,0,0,.884,2.294,2.969,2.969,0,0,0,2.154.931H16.152a2.968,2.968,0,0,0,2.154-.931,3.326,3.326,0,0,0,.884-2.294V7.662A2.3,2.3,0,0,0,18.6,3.133H15.513V2.38A2.368,2.368,0,0,0,13.126,0H7.773A2.368,2.368,0,0,0,5.386,2.38v.754H2.3a2.3,2.3,0,0,0-.591,4.528ZM16.152,24.533H4.747a1.909,1.909,0,0,1-1.832-2.019V7.715h15.07v14.8a1.909,1.909,0,0,1-1.832,2.019ZM6.592,2.38A1.161,1.161,0,0,1,7.773,1.2h5.353A1.161,1.161,0,0,1,14.308,2.38v.754H6.592ZM2.3,4.339H18.6a1.085,1.085,0,0,1,0,2.17H2.3a1.085,1.085,0,0,1,0-2.17Zm0,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                    <path
                      id="Path_85"
                      data-name="Path 85"
                      d="M164,154.7a.6.6,0,0,0-.6.6V166.7a.6.6,0,0,0,1.206,0V155.306A.6.6,0,0,0,164,154.7Zm0,0"
                      transform="translate(-153.551 -145.379)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openDeletePopup ? 'active' : ''}></div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Session);
