import React, { Component } from 'react';
import Form from './containers/form';
import actions from './store/actions';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import actionsApp from '../../js/actions';

const mapStateToProps = (state) => ({
  bill: state.BillReducer.bill,
  isLoadingBill: state.BillReducer.isLoadingBill,
  isLoadingLastBill: state.BillReducer.isLoadingLastBill,
  lastBill: state.AppReducer.lastBill,
  customersList: state.AppReducer.customersList,
  isLoadingCustomersLis: state.AppReducer.isLoadingCustomersLis,
});

const mapDispatchToProps = (dispatch) => ({
  getBillById: (id) => dispatch(actions.getBillById(id)),
  getLastBill: () => dispatch(actionsApp.getLastBill()),
  getListCustomerByLawyer: () => dispatch(actionsApp.getListCustomerByLawyer()),
});

class FormBill extends Component {
  constructor(props) {
    super(props);
    if (props.match.params && props.match.params.id) {
      props.changeActiveItemSideBar(7, 'تحديث فاتورة');
      document.title = 'تحديث فاتورة';
    } else {
      props.changeActiveItemSideBar(7, 'اضافة فاتورة');
      document.title = 'اضافة فاتورة';
    }
    props.getLastBill();
    props.getListCustomerByLawyer();
    props.getBillById(props.match.params && props.match.params.id);
    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const { bill, customersList, isLoadingBill, lastBill, isLoadingLastBill } =
      this.props;
    if (!lastBill) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    if (isLoadingBill) {
      return (
        <CircularProgress
          style={{
            textAlign: 'center',
            width: '30px',
            height: '30px',
            marginTop: '20%',
            marginRight: '50%',
          }}
        />
      );
    }
    return (
      <div style={{ marginBottom: '5%' }} className="clients_newClient show_block">
        <Form
          goBack={this.goBack}
          customersList={customersList}
          bill={bill}
          lastBill={lastBill}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormBill);
