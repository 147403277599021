import React, { Component } from 'react';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import InputField from '../../../components/inputField';
import { cities, host } from '../../../utils/constants';
import { firstNumberPhone, isNumeric, lengthPhone } from '../../../utils/helpres';

const mapStateToProps = (state, ownProps) => {
  const { user } = ownProps;
  let tva;
  let zip_code;
  let first_name;
  let last_name;
  let email;
  let fax_number;
  let fix_number;
  let phone;
  let code;
  let office_code;
  let address_country;
  let address_city;
  let address_street;
  let type;
  let id;
  let adjective;
  let photo;
  let seal;
  let signature;
  if (user) {
    id = user && user.id ? user.id : null;
    tva = user && user.tva ? user.tva : 0;
    signature = user && user.signature ? user.signature : null;
    seal = user && user.seal ? user.seal : null;
    photo = user && user.photo ? user.photo : null;
    adjective = user && user.adjective ? user.adjective : null;
    first_name = user && user.first_name ? user.first_name : '';
    zip_code = user && user.zip_code ? user.zip_code : '';
    email = user && user.email ? user.email : '';
    last_name = user && user.last_name ? user.last_name : '';
    fax_number = user && user.fax_number ? user.fax_number : '';
    fix_number = user && user.fix_number ? user.fix_number : '';
    phone = user && user.phone ? user.phone : '';
    type = user && user.type ? user.type : '';
    address_street = user && user.address_street ? user.address_street : '';
    address_city = user && user.address_city ? user.address_city : '';
    address_country = user && user.address_country ? user.address_country : '';
    office_code = user && user.office_code ? user.office_code : '';
    code = user && user.code ? user.code : '';
  }

  return {
    get_img_account: state.AppReducer.get_img_account,
    updateAccount: state.AppReducer.updateAccount,
    isLoadingUpdateAccount: state.AppReducer.isLoadingUpdateAccount,
    account: state.AppReducer.account,
    initialValues: {
      id: id || null,
      tva: tva || 0,
      photo: photo || '',
      seal: seal || '',
      signature: signature || '',
      phone: phone || '',
      adjective: adjective || '',
      type: type || '',
      address_street: address_street || '',
      address_country: address_country || '',
      address_city: address_city || '',
      zip_code: zip_code || '',
      first_name: first_name || '',
      last_name: last_name || '',
      email: email || '',
      code: code || '',
      fix_number: fix_number || '',
      fax_number: fax_number || '',
      office_code: office_code || '',
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitUserForm: (values) => dispatch(actions.submitUserForm(values)),
  getImg: (values) => dispatch(actions.getImg(values)),
  submitLawyerTraineeShipForm: (values) =>
    dispatch(actions.submitLawyerTraineeShipForm(values)),
});
class Info extends Component {
  constructor(props) {
    super(props);
    document.title = 'معطيات المستخدم';
    props.changeActiveItemSideBar(10, 'معطيات المستخدم');
    if (props.initialValues.photo) {
      props.getImg(props.initialValues.photo);
    }
    this.state = {
      hasError: false,
      openModel: false,
      initialValues: {
        trimmedDataURL: null,
        fileUrl: '',
        ...props.initialValues,
      },
    };
    this.submitForm = this.submitForm.bind(this);
  }
  closeModal() {
    this.setState({
      openModel: false,
    });
  }
  clear = () => {
    this.sigPad.clear();
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        trimmedDataURL: null,
        signature: null,
      },
    });
  };
  trim = () => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
        signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
      },
    });
  };
  submitForm(initialValues) {
    if (
      (initialValues.phone && !firstNumberPhone(initialValues.phone)) ||
      (initialValues.phone && !isNumeric(initialValues.phone)) ||
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      !initialValues.last_name ||
      !initialValues.first_name ||
      !initialValues.address_city ||
      //!initialValues.address_country ||
      !initialValues.address_street ||
      !initialValues.phone ||
      (initialValues.fix_number && !firstNumberPhone(initialValues.fix_number)) ||
      (initialValues.fix_number && !isNumeric(initialValues.fix_number)) ||
      (initialValues.tva && !isNumeric(initialValues.tva)) ||
      (initialValues.fix_number && !lengthPhone(initialValues.fix_number)) ||
      !initialValues.fix_number ||
      // !initialValues.office_code ||
      !initialValues.zip_code
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      if (this.props.account.pack === 'traineeship')
        this.props.submitLawyerTraineeShipForm(initialValues);
      else this.props.submitUserForm(initialValues);
    }
  }

  render() {
    const { openModel, fileUrl, initialValues, hasError } = this.state;
    const { get_img_account } = this.props;
    return (
      <form action="">
        <div style={{ height: '25rem' }} className="params_basic_details">
          <div className="params_basic_feilds_section">
            <div className="param_basic_details__field">
              <label htmlFor="params_username">الإسم</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.first_name}
                name="first_name"
                placeholder="الاسم"
                type="text"
                hasError={hasError}
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      first_name: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="param_basic_details__field">
              <label htmlFor="params_phone">اللقب</label>
              <InputField
                // onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.last_name}
                name="last_name"
                placeholder="اللقب"
                type="text"
                hasError={hasError}
                validInput="text"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      last_name: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="param_basic_details__field">
              <label htmlFor="address">البريد الالكتروني</label>
              <InputField
                // onKeyPress={this.keyPressed}
                value={initialValues.email}
                name="email"
                disabled={true}
                placeholder="البريد الالكتروني "
                type="email"
                hasError={hasError}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="param_basic_details__field">
              <label htmlFor="params_state">الجدول </label>
              <select
                style={{ width: '32%', border: '1px solid #cbcbcb' }}
                value={initialValues.adjective}
                className={hasError && !initialValues.adjective ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      adjective: e.target.value,
                    },
                  });
                }}
                name="adjective"
              >
                <option value="">اختر</option>
                <option value="الإستئناف">الإستئناف</option>
                <option value="التعقيب">التعقيب</option>
                <option value="متمرن">متمرن</option>
              </select>
              <label style={{ marginRight: 10 }} htmlFor="address">
                TVA{' '}
              </label>
              <InputField
                // onKeyPress={this.keyPressed}
                value={initialValues.tva}
                name="tva"
                placeholder="TVA "
                type="text"
                hasError={hasError}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      tva: e.target.value,
                    },
                  });
                }}
              />
              {/*<InputField
                // onKeyPress={this.keyPressed}
                value={initialValues.adjective}
                name="adjective"
                placeholder="تعقيب"
                type="text"
                hasError={hasError}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      adjective: e.target.value,
                    },
                  });
                }}
              />*/}
            </div>
          </div>
          <div className="params_basic_pic_section">
            {typeof initialValues.photo === 'string' && initialValues.photo ? (
              <img
                style={{
                  maxWidth: '15rem',
                  minWidth: '15rem',
                  height: '70%',
                  width: '75%',
                  borderRadius: '10px',
                }}
                src={get_img_account}
              />
            ) : initialValues.photo &&
              initialValues.photo.length > 0 &&
              initialValues.photo[0].name ? (
              <div className="miniature-container">
                <img
                  style={{
                    maxWidth: '15rem',
                    minWidth: '15rem',
                    height: '70%',
                    width: '75%',
                    borderRadius: '10px',
                  }}
                  src={URL.createObjectURL(initialValues.photo[0])}
                />
              </div>
            ) : (
              <svg
                className="params_user_pic"
                xmlns="http://www.w3.org/2000/svg"
                width="166"
                height="165"
                viewBox="0 0 166 165"
              >
                <rect
                  id="Rectangle_170"
                  data-name="Rectangle 170"
                  width="166"
                  height="165"
                  rx="9"
                  fill="#f3f3f3"
                />
                <path
                  id="user_1_"
                  data-name="user (1)"
                  d="M100.426,17.23a58.828,58.828,0,0,0-83.2,83.2,58.828,58.828,0,0,0,83.2-83.2ZM29.492,101.659a29.788,29.788,0,0,1,58.672,0,51.821,51.821,0,0,1-58.672,0ZM40.123,51.444A18.705,18.705,0,1,1,58.828,70.149,18.726,18.726,0,0,1,40.123,51.444ZM94.135,96.877A36.74,36.74,0,0,0,72.776,72.9a25.6,25.6,0,1,0-27.9,0A36.735,36.735,0,0,0,23.521,96.877a51.934,51.934,0,1,1,70.614,0Zm0,0"
                  transform="translate(24.172 26.122)"
                  fill="#fff"
                />
              </svg>
            )}
            <div className="params_set_user_pic">
              <input
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      photo: e.target.files,
                    },
                  });
                }}
                name="photo"
                type="file"
                id="upload_button"
                accept=".jpg,.png"
                hidden
              />
              <label style={{ minWidth: '1rem', width: '40%' }} htmlFor="upload_button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35.014"
                  height="27.558"
                  viewBox="0 0 35.014 27.558"
                >
                  <g id="camera" transform="translate(0 -44.8)">
                    <g id="Group_107" data-name="Group 107" transform="translate(0 44.8)">
                      <g id="Group_106" data-name="Group 106" transform="translate(0 0)">
                        <path
                          id="Path_147"
                          data-name="Path 147"
                          d="M33.849,49.094a3.764,3.764,0,0,0-2.762-1.165H25.561v-.067a2.988,2.988,0,0,0-.9-2.163,3.045,3.045,0,0,0-2.163-.9H12.514a3.068,3.068,0,0,0-3.1,3.062v.067H3.927a3.764,3.764,0,0,0-2.762,1.165A3.957,3.957,0,0,0,0,51.856V68.431a3.764,3.764,0,0,0,1.165,2.762,3.957,3.957,0,0,0,2.762,1.165H31.086a3.764,3.764,0,0,0,2.762-1.165,3.957,3.957,0,0,0,1.165-2.762V51.856A3.764,3.764,0,0,0,33.849,49.094Zm-.566,19.337H33.25a2.159,2.159,0,0,1-2.163,2.163H3.927a2.159,2.159,0,0,1-2.163-2.163V51.856a2.159,2.159,0,0,1,2.163-2.163h6.424a.9.9,0,0,0,.9-.9v-.965a1.253,1.253,0,0,1,1.3-1.3H22.5a1.253,1.253,0,0,1,1.3,1.3v.965a.9.9,0,0,0,.9.9H31.12a2.159,2.159,0,0,1,2.163,2.163Z"
                          transform="translate(0 -44.8)"
                          fill="#ca2946"
                        />
                        <path
                          id="Path_148"
                          data-name="Path 148"
                          d="M120.188,130.8a8.182,8.182,0,1,0,5.791,2.4A8.2,8.2,0,0,0,120.188,130.8Zm4.526,12.747a6.428,6.428,0,0,1-9.053,0,6.363,6.363,0,0,1-1.864-4.526,6.5,6.5,0,0,1,1.864-4.526,6.363,6.363,0,0,1,4.526-1.864,6.5,6.5,0,0,1,4.526,1.864,6.363,6.363,0,0,1,1.864,4.526A6.228,6.228,0,0,1,124.714,143.547Z"
                          transform="translate(-102.681 -123.644)"
                          fill="#ca2946"
                        />
                        <circle
                          id="Ellipse_35"
                          data-name="Ellipse 35"
                          cx="1.631"
                          cy="1.631"
                          r="1.631"
                          transform="translate(27.725 7.123)"
                          fill="#ca2946"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </label>
            </div>
          </div>
        </div>
        <div className="params_user_details_gap"></div>
        <div className="params_more_details">
          <div className="params_field_grp">
            <div className="param_basic_details__field">
              <label htmlFor="params_state">الهاتف</label>
              <InputField
                //onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.phone}
                name="phone"
                placeholder="الهاتف"
                type="text"
                hasError={hasError}
                validInput="tel"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      phone: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="param_basic_details__field">
              <label id="params_less_width_label" htmlFor="params_street">
                رقم المكتب
              </label>
              <InputField
                // onKeyPress={() => this.submitForm(initialValues)}
                value={initialValues.fix_number}
                name="fix_number"
                placeholder="رقم المكتب"
                type="text"
                hasError={hasError}
                validInput="tel"
                onChange={(event) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      fix_number: event.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="params_user_details_gap"></div>
        <div className="params_more_details">
          <div className="param_basic_details__field">
            <label htmlFor="params_addresess">العنوان</label>
            <textarea
              onChange={(event) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    address_street: event.target.value,
                  },
                });
              }}
              value={initialValues.address_street}
              className={hasError && !initialValues.address_street ? 'input-error' : ''}
              name="address_street"
              id="lawsuit_events"
            >
              {initialValues.address_street}
            </textarea>
          </div>
          <div className="params_field_grp">
            <div className="param_basic_details__field">
              <label htmlFor="params_state">الولاية</label>
              <select
                className={hasError && !initialValues.address_city ? 'input-error' : ''}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      address_city: e.target.value,
                    },
                  });
                }}
                value={initialValues.address_city}
              >
                <option value="">الولاية</option>
                {cities.map((city) => {
                  return (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="param_basic_details__field">
              <label htmlFor="params_postal_code">الترقيم البريدي</label>
              <InputField
                //  onKeyPress={this.keyPressed}
                value={initialValues.zip_code}
                name="zip_code"
                placeholder="الترقيم البريدي"
                type="text"
                validInput="integer"
                hasError={hasError}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      zip_code: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '2rem' }} className="lawsuit_form__btn_grp">
          <button
            onClick={() => this.props.goBack()}
            type="button"
            className="lawsuit_cancel_session"
          >
            <svg
              className="lawsuit_ltSpan"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="http://svgjs.com/svgjs"
              version="1.1"
              width="12"
              height="12"
              x="0"
              y="0"
              viewBox="0 0 492.004 492.004"
              style={{ enableBackground: 'new 0 0 512 512' }}
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
                      fill="#2d2d2d"
                      data-original="#000000"
                      className=""
                    />
                  </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
                <g xmlns="http://www.w3.org/2000/svg"></g>
              </g>
            </svg>
            الغاء
          </button>
          <button onClick={() => this.submitForm(initialValues)} type="button">
            <svg
              className="tickSpan"
              xmlns="http://www.w3.org/2000/svg"
              width="20.125"
              height="17.774"
              viewBox="0 0 29.125 23.774"
            >
              <path
                id="Path_92"
                data-name="Path 92"
                d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                transform="translate(-1323.842 -156.357)"
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="4"
              />
            </svg>
            تسجيل
          </button>
        </div>
        <div className={openModel ? 'popup active' : 'popup'}>
          <div className="popup__title">التوقيع</div>
          <div className="popup__item">
            {initialValues.seal ? (
              <img style={{ marginRight: '30%' }} src={initialValues.seal} />
            ) : null}
          </div>
          <div className="popup__btn_grp">
            <button
              type="button"
              onClick={() => {
                this.clear();
                this.closeModal();
              }}
              className="cancel_popup"
            >
              <span>الغاء التوقيع</span>
              <span>&lt;</span>
            </button>
            <button
              style={{
                background: '#63B17D',
              }}
              type="button"
              onClick={() => {
                this.setState({
                  openModel: false,
                });
                this.trim();
              }}
            >
              <span>تأكيد</span>
              <span className="trashSpan">
                <svg
                  className="tickSpan"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.125"
                  height="17.774"
                  viewBox="0 0 29.125 23.774"
                >
                  <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M1325.354,167.5l8.34,9.625,17.8-19.417"
                    transform="translate(-1323.842 -156.357)"
                    fill="none"
                    stroke="#f9f9f9"
                    strokeWidth="4"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div id="overlay" className={openModel ? 'active' : ''}></div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Info);
