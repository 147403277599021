import React, { Component } from 'react';
import actions from '../../js/actions';
import actionsLawsuit from './store/actions';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import Filter from './components/filter';
import { Link } from 'react-router-dom';
import Show from './containers/show';
import { isHasPermission } from '../../utils/helpres';
import actionsApp from '../../js/actions';
const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  lawsuits: state.AppReducer.lawsuits,
  isLoadingLawsuits: state.AppReducer.isLoadingLawsuits,
  paramsLawsuits: state.AppReducer.paramsLawsuits,
  destroyLawsuit: state.LawsuitReducer.destroyLawsuit,
  isLoadingDestroyLawsuit: state.LawsuitReducer.isLoadingDestroyLawsuit,
  shareLawsuit: state.LawsuitReducer.shareLawsuit,
  isLoadingShareLawsuit: state.LawsuitReducer.isLoadingShareLawsuit,
  paramsCustomer: state.AppReducer.paramsCustomer,
  customers: state.AppReducer.customers,
  isLoadingCustomers: state.AppReducer.isLoadingCustomers,
  lawyers: state.AppReducer.lawyers,
  isLoadingLawyers: state.AppReducer.isLoadingLawyers,
});

const mapDispatchToProps = (dispatch) => ({
  getListCustomer: (paramsCustomer) => dispatch(actions.getListCustomer(paramsCustomer)),
  getListLawsuits: (paramsLawsuits) =>
    dispatch(actions.getListLawsuits(paramsLawsuits, 0)),
  changeSearchedStringLawsuits: async (paramsLawsuits) => {
    await dispatch(actions.changeSearchedStringLawsuits(paramsLawsuits));
    dispatch(actions.getListLawsuits(paramsLawsuits, 0));
  },
  deleteLawsuit: async (paramsLawsuits, id) => {
    await dispatch(actionsLawsuit.deleteLawsuit(id));
    dispatch(actions.getListLawsuits(paramsLawsuits, 0));
  },
  getListLawyers: () => dispatch(actionsApp.getListLawyers()),
  getLawsuitById: (id) => dispatch(actionsLawsuit.getLawsuitById(id)),
  submitShareLawsuit: (values) => dispatch(actionsLawsuit.submitShareLawsuit(values)),
});

class Lawsuit extends Component {
  constructor(props) {
    super(props);
    props.getListCustomer(props.paramsCustomer);
    props.changeActiveItemSideBar(3, 'الدعاوى');
    document.title = 'الدعاوى';
    this.state = {
      anchor: false,
      left: false,
      selected_id: null,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStringLawsuits({
      search: '',
      date: '',
      customer_id:
        this.props.location.state && this.props.location.state.customer_id
          ? this.props.location.state.customer_id
          : null,
      currentPage: 1,
      limit: 150,
      order: 'DESC',
    });
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    this.props.getLawsuitById(id);
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
    });
  };
  render() {
    const {
      lawsuits,
      customers,
      account,
      destroyLawsuit,
      isLoadingLawsuits,
      paramsLawsuits,
      isLoadingLawyers,
      lawyers,
      isLoadingShareLawsuit,
      shareLawsuit,
    } = this.props;
    console.log(lawsuits);
    let data = [];
    if (customers && customers.data && customers.data.length > 0) {
      data = customers.data;
    }
    const { anchor, left, selected_id } = this.state;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-الدعاوى',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-الدعاوى',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-الدعاوى',
    );
    return (
      <div className="jalaset_main">
        {left && selected_id ? (
          <Show
            left={left}
            anchor={anchor}
            selected_id={selected_id}
            toggleDrawer={this.toggleDrawer}
          />
        ) : (
          ''
        )}
        <div className="jalaset_middle">
          <div className="table_controls">
            {isPermissionAdd ? (
              <Link to="/add-lawsuit" className="jalaset_add_button">
                <svg
                  height="10"
                  viewBox="0 0 448 448"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0" />
                </svg>
                اضافة دعوى
              </Link>
            ) : (
              ''
            )}

            <Link to="/lawsuits-remotely" className="jalaset_add_button">
              متابعة القضايا عن بعد
            </Link>
            <div className="sort_grp">
              <div className="sort_grp__txt">ترتيب حسب</div>
              <Filter
                data={data}
                selectedItem={
                  this.props.location.state && this.props.location.state.customer_id
                }
                changeSearchedStringLawsuits={this.props.changeSearchedStringLawsuits}
                paramsSessions={paramsLawsuits}
              />
            </div>
          </div>
          <Table
            class1="lawsuit__judgements_table"
            class2="lawsuit_table"
            withTitle={false}
            colSpan={6}
            dataTable="lawsuits"
            messageDelete="أنت بصدد حذف الدعوى"
            action={true}
            isLoading={isLoadingLawsuits}
            params={paramsLawsuits}
            changeSearchedString={this.props.changeSearchedStringLawsuits}
            getListLawyers={this.props.getListLawyers}
            submitShareLawsuit={this.props.submitShareLawsuit}
            toggleDrawer={this.toggleDrawer}
            show={true}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            account={account}
            data={lawsuits}
            //sessions={sessions}
            title="الجلسات"
            editPath="edit-lawsuit"
            deletePath={this.props.deleteLawsuit}
            destroy={destroyLawsuit}
            destroyYes={true}
            isLoadingLawyers={isLoadingLawyers}
            shareLawsuit={shareLawsuit}
            isLoadingShareLawsuit={isLoadingShareLawsuit}
            lawyers={lawyers}
            headerItems={[
              'عدد الدعوى',
              'المدعي',
              'تاريخ الجلسة',
              'نوع الدعوى',
              'المحكمة',
              'الإعدادت',
            ]}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Lawsuit);
