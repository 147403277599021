import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'; // Import Quill's snow theme

const ArabicEditor = ({ initialValues, onChange }) => {
  const [editorContent, setEditorContent] = useState(initialValues.text);
  const quillRef = useRef();
  const generatePdf = () => {
    const editorContent = quillRef.current.getEditor().root.innerHTML;

    // Open a new window to print
    const newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            body { font-family: Arial, sans-serif; direction: rtl; text-align: right; } /* Ensure RTL for Arabic */
            h1, h2, h3, p { margin: 0; padding: 0; }
          </style>
        </head>
        <body>
          ${editorContent}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.close();
  };
  const handleEditorChange = (value) => {
    setEditorContent(value);
    onChange(value);
  };

  return (
    <div>
      <h2>عقد بيع</h2>
      <div className="popup__btn_grp">
        <button className="jalaset_add_button" onClick={generatePdf}>
          <span>تحميل PDF</span>
        </button>
      </div>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorContent}
        onChange={handleEditorChange}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ direction: 'rtl' }], // Add RTL direction tool
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
            ['link'],
            ['clean'], // removes formatting
            ['print'],
          ],
        }}
        placeholder="عقد بيع..."
        style={{ textAlign: 'right' }} // Ensure text starts from the right side
      />
    </div>
  );
};

export default ArabicEditor;
