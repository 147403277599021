import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';

const submitCustomerForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CUSTOMER_REQUESTING' });
  const {
    name,
    phone,
    cin,
    number_field,
    legal_agent,
    nationality,
    post,
    address_city,
    address_street,
  } = values;
  let request = {
    name,
    phone,
    address_city,
    address_street,
    nationality,
    cin,
    post,
    legal_agent,
    number_field,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/customer/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_CUSTOMER_SUCCESS',
        addCustomer: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CUSTOMER_FAILURE',
        ErrorMessage: data.message,
        addCustomer: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء الحريف ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CUSTOMER_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addCustomer: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء الحريف ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CUSTOMER_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addCustomer: values,
        status: getStatusError(e),
      });
    }
  }
};

const getCustomerById = (id) => async (dispatch) => {
  dispatch({
    type: 'GET_CUSTOMER_REQUESTING',
  });
  try {
    let url = `/api/customer/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CUSTOMER_SUCCESS',
      customer: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CUSTOMER_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const deleteCustomer = (id, type) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_CUSTOMER_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/customer/delete/${id}?type=${type}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_CUSTOMER_SUCCESS',
        destroyCustomer: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_CUSTOMER_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف الحريف" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_CUSTOMER_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};
export default {
  getCustomerById,
  submitCustomerForm,
  deleteCustomer,
};
