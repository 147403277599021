import axios from 'axios';
import { getStatusError } from '../../../utils/helpres';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { apiCall } from '../../../js/actions';
import moment from 'moment';
const ERROR_MAINTENANCE = 503;
const getContractById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_CONTRACT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/contract/get-one/${id}`,
    });
    dispatch({ type: 'GET_CONTRACT_SUCCESS', contract: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_CONTRACT_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitContractForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CONTRACT_REQUESTING' });
  const {
    date,
    second_party_cin,
    responsible_id,
    second_party_name,
    second_party_post,
    second_party_address,
    second_party_nationality,
    product_type,
    product_engine,
    product_engine_number,
    type_contract_id,
    product_creator,
    product_year,
    price,
    fees,
    customer_id,
    text,
    number,
  } = values;
  let request = {
    date,
    number,
    fees,
    customer_id,
    second_party_cin,
    second_party_name,
    second_party_post,
    second_party_address,
    second_party_nationality,
    responsible_id,
    product_type,
    product_engine,
    product_engine_number,
    type_contract_id,
    product_creator,
    text,
    product_year: moment(product_year).format('YYYY-MM-DD'),
    price,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/contract/${values.id ? `update/${values.id}` : 'create'}`,
      data: request,
    });
    if (data.type === 'success') {
      if (values.files && values.files.length > 0) {
        await values.files.map(async (item) => {
          let formData = new FormData();
          formData.append('file', item.file);
          formData.append('type', 'lawsuit');
          formData.append('type_doc', 'documents');
          formData.append('id', data.data.id);
          await apiCall({
            method: 'post',
            url: `/api/upload`,
            data: formData,
          });
        });
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        dispatch({
          type: 'UPDATE_CONTRACT_SUCCESS',
          addContract: data.data,
        });
      } else {
        toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
          hideProgressBar: true,
        });
        dispatch({
          type: 'UPDATE_CONTRACT_SUCCESS',
          addContract: data.data,
        });
      }
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONTRACT_FAILURE',
        ErrorMessage: data.message,
        addContract: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      if (data.message) {
        toast(
          ({ closeToast }) => (
            <ToastAction type="error" message={response.data.message} />
          ),
          { hideProgressBar: true },
        );
      } else
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="error"
              message="عفوًا ، لم يتم إنشاء العقد ، يرجى إعادة المحاولة."
            />
          ),
          { hideProgressBar: true },
        );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONTRACT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addContract: values,
        status: getStatusError(e),
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message="عفوًا ، لم يتم إنشاء العقد ، يرجى إعادة المحاولة."
          />
        ),
        { hideProgressBar: true },
      );
      // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONTRACT_FAILURE',
        msg: 'error server',
        err: data.errors || data.message,
        addContract: values,
        status: getStatusError(e),
      });
    }
  }
};

const deleteContract = (id, type) => async (dispatch) => {
  dispatch({ type: 'ACTION_DELETE_CONTRACT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/contract/delete/${id}?type=${type}`,
    });
    if (data.type === 'success') {
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_CONTRACT_SUCCESS',
        destroyContract: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'ACTION_DELETE_CONTRACT_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message="لم يتم حذف العقد" />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'ACTION_DELETE_CONTRACT_FAILURE',
      msg: 'error server',
      err: data.errors || data.message,
      data: false,
    });
  }
};

export default {
  getContractById,
  submitContractForm,
  deleteContract,
};
