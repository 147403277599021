import React, { Component } from 'react';
import actions from '../../../js/actions';
import { connect } from 'react-redux';
import Table from '../../../components/Table';
import { Link } from 'react-router-dom';
import actionsLawsuit from '../../lawsuit/store/actions';
import { isHasPermission } from '../../../utils/helpres';

const mapStateToProps = (state) => ({
  account: state.AppReducer.account,
  lawsuits: state.AppReducer.lawsuits,
  paramsLawsuits: state.AppReducer.paramsLawsuits,
  isLoadingLawsuits: state.AppReducer.isLoadingLawsuits,
  destroyLawsuit: state.LawsuitReducer.destroyLawsuit,
});

const mapDispatchToProps = (dispatch) => ({
  getListLawsuits: (paramsLawsuits) =>
    dispatch(actions.getListLawsuits(paramsLawsuits, 1)),
  deleteLawsuit: async (paramsLawsuits, id) => {
    await dispatch(actionsLawsuit.deleteLawsuit(id, 'delete'));
    dispatch(actions.getListLawsuits(paramsLawsuits, 1));
  },
  changeSearchedStringLawsuits: async (paramsLawsuits) => {
    await dispatch(actions.changeSearchedStringLawsuits(paramsLawsuits));
    dispatch(actions.getListLawsuits(paramsLawsuits, 1));
  },
});

class LawsuitArchive extends Component {
  constructor(props) {
    super(props);
    console.log(props.paramsLawsuits);
    props.getListLawsuits(props.paramsLawsuits);
    props.changeActiveItemSideBar(6, 'أرشيف الدعاوى');
    document.title = 'أرشيف الدعاوى';
  }
  render() {
    const { lawsuits, account, destroyLawsuit, isLoadingLawsuits, paramsLawsuits } =
      this.props;
    let isPermissionEdit = isHasPermission(
      account.login_access.permissions,
      'تعديل-الدعاوى',
    );
    let isPermissionDelete = isHasPermission(
      account.login_access.permissions,
      'حذف-الدعاوى',
    );
    let isPermissionAdd = isHasPermission(
      account.login_access.permissions,
      'إضافة-الدعاوى',
    );
    return (
      <div className="jalaset_main">
        <div className="jalaset_middle">
          <Table
            class1="lawsuit__judgements_table"
            class2="lawsuit_table"
            withTitle={false}
            colSpan={6}
            dataTable="lawsuits"
            messageDelete="أنت بصدد حذف الدعوى"
            action={true}
            isLoading={isLoadingLawsuits}
            params={paramsLawsuits}
            changeSearchedString={this.props.changeSearchedStringLawsuits}
            show={false}
            isPermissionEdit={isPermissionEdit}
            isPermissionDelete={isPermissionDelete}
            isPermissionAdd={isPermissionAdd}
            account={account}
            data={lawsuits}
            //sessions={sessions}
            title="الجلسات"
            deletePath={this.props.deleteLawsuit}
            destroy={destroyLawsuit}
            destroyYes={true}
            headerItems={[
              'عدد الدعوى',
              'المدعي',
              'تاريخ الجلسة',
              'نوع الدعوى',
              'المحكمة',
              'الإعدادت',
            ]}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LawsuitArchive);
